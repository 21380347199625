import { useContext, useState, useEffect } from "react";
import { AnsweredContext } from "../pages/Home";
import { uri } from "../App";
import { handleSubmit } from "./uitls";
import { stringFix } from "./uitls";

export default function MultipleSelect(props) {
    document.getElementById("center").classList.add("fade-in");
    const [largestHeight, setLargestHeight] = useState(0);
    var buttons = document.getElementsByClassName("select-inner");
    useEffect(() => {
        if (buttons.length > 1) {
            let i = 0,
                len = buttons.length;
            while (i < len) {
                if (buttons[i].offsetHeight > largestHeight) {
                    setLargestHeight(buttons[i].offsetHeight);
                }
                i++;
            }
        }
    }, [largestHeight, buttons]);
    const [Selected, setSelected] = useState([]);
    /* eslint-disable no-unused-vars */
    const [answered, setAnswered] = useContext(AnsweredContext);
    /* eslint-enable no-unused-vars */
    if (document.body.classList.contains("waiting")) {
        document.body.classList.remove("waiting");
    }

    function handleSelect(choice) {
        //If choice is already selected, remove it
        if (Selected.some((item) => item === choice)) {
            var temp = Selected;
            var index = temp.indexOf(choice);
            if (index !== -1) {
                temp.splice(index, 1);
                setSelected(temp);
                document
                    .getElementById(choice.replace(/\s/g, "-"))
                    .classList.remove("choice-selected");
            }
            //if choice is none then remove all and add none
        } else if (choice === "none of these") {
            setSelected([choice]);
            document
                .getElementById(choice.replace(/\s/g, "-"))
                .classList.add("choice-selected");
            if (Selected.length > 0) {
                Selected.forEach((s) => {
                    document
                        .getElementById(s)
                        .classList.remove("choice-selected");
                    return;
                });
            }
            // if replace "none of these"
        } else {
            if (Selected.some((item) => item === "none of these")) {
                setSelected([choice]);
                document
                    .getElementById("none-of-these")
                    .classList.remove("choice-selected");
                document
                    .getElementById(choice.replace(/\s/g, "-"))
                    .classList.add("choice-selected");
                //adding to array
            } else if (Selected.length < props.maxOptions) {
                setSelected((current) => [...current, choice]);
                document
                    .getElementById(choice.replace(/\s/g, "-"))
                    .classList.add("choice-selected");
            }
        }
    }

    function handlePreSubmit(s) {
        const preText = '["';
        const endText = '"]';
        var parseText = preText.concat(s.toString().replace(/,/g, '","'));
        var resultText = parseText.concat(endText);
        handleSubmit(
            "",
            "",
            JSON.parse(resultText),
            props.id,
            props.session,
            setAnswered,
            2
        );
    }

    return (
        <>
            <div className="row">
                <div className="col-10 offset-1 text-center">
                    <h1 className="title">{props.data.question}</h1>
                    <p>
                        {props.maxOptions === props.data.choices.length
                            ? "Select all that apply"
                            : "Select up to " + props.maxOptions}
                    </p>
                </div>
            </div>
            <div
                className={props.isImage ? "row row-cols-3" : "row row-cols-2"}>
                {props.data.choices.map((choice, index) => {
                    return props.isImage ? (
                        <div
                            key={choice}
                            className={
                                props.data.choices.length % 2 === 0
                                    ? "col text-center mt-3"
                                    : index + 1 === props.data.choices.length
                                    ? "col-6 offset-3 text-center mt-3"
                                    : "col text-center mt-3"
                            }>
                            <img
                                className="img-fluid"
                                src={uri + choice}
                                alt={choice.split("/")[2].split(".")[0]}
                                id={choice.split("/")[2].split(".")[0]}
                                onClick={(e) =>
                                    handleSelect(
                                        choice.split("/")[2].split(".")[0]
                                    )
                                }
                            />
                        </div>
                    ) : (
                        <div
                            key={choice}
                            className={
                                props.data.choices.length % 2 === 0
                                    ? "col text-center mt-3"
                                    : index + 1 === props.data.choices.length
                                    ? "col-6 offset-3 text-center mt-3"
                                    : "col text-center mt-3"
                            }>
                            <button
                                style={{ height: largestHeight }}
                                id={choice.replace(/\s/g, "-").toLowerCase()}
                                onClick={(e) => {
                                    handleSelect(choice.toLowerCase());
                                }}
                                className="multiple-select cap">
                                <div className="select-inner">
                                    {typeof stringFix(choice) === "string"
                                        ? stringFix(choice)
                                        : stringFix(choice)[0]}
                                </div>
                                {/* //*Sub text */}
                                {typeof stringFix(choice) !== "string" ? (
                                    <p className="sub-choice">
                                        {stringFix(choice)[1]}
                                    </p>
                                ) : (
                                    <></>
                                )}
                            </button>
                        </div>
                    );
                })}
            </div>
            <div className="row mt-3">
                <div className="col-8 offset-2 text-center">
                    <span className="loading-btn-wrapper">
                        <button
                            onClick={(e) => {
                                handlePreSubmit(Selected);
                            }}
                            id="submit"
                            className="loading-btn">
                            <span className="loading-btn__text">Submit</span>
                        </button>
                    </span>
                </div>
            </div>
        </>
    );
}
