import { useContext, useState } from "react";
import { AnsweredContext } from "../pages/Home";
import { handleSubmit } from "./uitls";

export default function Number(props) {
    document.getElementById("center").classList.add("fade-in");
    if (document.body.classList.contains("waiting")) {
        document.body.classList.remove("waiting");
    }
    /* eslint-disable no-unused-vars */
    const [answered, setAnswered] = useContext(AnsweredContext);
    /* eslint-enable no-unused-vars */
    const [formState, setFormState] = useState({
        answer: 0,
    });

    return (
        <>
            <div className="row">
                <div className="col-12 text-center">
                    <h1>{props.data.question}</h1>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-6 offset-3 text-center">
                    <div>
                        <input
                            className="text-center number-display"
                            type="number"
                            readOnly
                            value={formState.answer ? formState.answer : 0}
                        />
                    </div>
                    <div className="slider-wrapper">
                        <input
                            onChange={(e) =>
                                setFormState({
                                    ...formState,
                                    answer: e.target.value,
                                })
                            }
                            value={formState.answer}
                            type="range"
                            min="1"
                            max="100"
                            className="slider"
                        />
                    </div>
                </div>
                <div className="col-8 offset-2 mt-5 text-center">
                    <span className="loading-btn-wrapper">
                        <button
                            onClick={(e) =>
                                handleSubmit(
                                    formState.answer,
                                    "",
                                    "{}",
                                    props.id,
                                    props.session,
                                    setAnswered,
                                    0
                                )
                            }
                            id="submit"
                            className="loading-btn">
                            <span className="loading-btn__text">Submit</span>
                        </button>
                    </span>
                </div>
            </div>
        </>
    );
}
