import { useContext, useEffect, useState } from "react";
import { AnsweredContext } from "../pages/Home";
import { handleSubmit } from "./uitls";

export default function MultipleChoice(props) {
    document.getElementById("center").classList.add("fade-in");
    const [largestHeight, setLargestHeight] = useState(0);
    const [titleStyle, setTitleStyle] = useState("");
    var titleLength = props.data.question.split("").length;
    useEffect(() => {
        titleLength < 50
            ? setTitleStyle("-2")
            : titleLength < 80
            ? setTitleStyle("-1")
            : setTitleStyle("");
    }, [titleLength]);
    var buttons = document.getElementsByClassName("loading-btn__text");
    useEffect(() => {
        if (buttons.length > 1) {
            var i = 0,
                len = buttons.length;
            while (i < len) {
                if (buttons[i].offsetHeight > largestHeight) {
                    setLargestHeight(buttons[i].offsetHeight);
                }
                i++;
            }
        }
    }, [buttons, largestHeight]);
    if (document.body.classList.contains("waiting")) {
        document.body.classList.remove("waiting");
    }
    /* eslint-disable no-unused-vars */
    const [answered, setAnswered] = useContext(AnsweredContext);
    /* eslint-enable no-unused-vars */
    function buttonPress(choice) {
        document.getElementById(choice).innerHTML = "";
        handleSubmit("", choice, "", props.id, props.session, setAnswered, 0);
    }
    var rows = props.data.choices.length > 6 ? true : false;
    return (
        <>
            <div className="row">
                <div className="col-10 offset-1 text-center">
                    <h1 className={"title" + titleStyle}>
                        {props.data.question}
                    </h1>
                    <p>Select one</p>
                </div>
            </div>
            <div className={rows ? "row row-cols-2" : ""}>
                {props.data.choices.map((choice, index) => {
                    return (
                        <div
                            key={choice}
                            className={
                                (!rows
                                    ? "row"
                                    : props.data.choices.length % 2 === 0
                                    ? "col"
                                    : index + 1 === props.data.choices.length
                                    ? "col-6 offset-3"
                                    : "col") + " mt-4"
                            }>
                            <div
                                className={
                                    (rows ? "col" : "col-10 offset-1") +
                                    " text-center"
                                }>
                                <span
                                    style={{ height: largestHeight + 10 }}
                                    className="loading-btn-wrapper">
                                    <button
                                        style={{ backgroundColor: "#4285f4" }}
                                        onClick={(e) => buttonPress(choice)}
                                        id={choice}
                                        className="loading-btn">
                                        <span className="loading-btn__text">
                                            {choice}
                                        </span>
                                    </button>
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
