// Hooks
import { useState, useEffect, createContext } from "react";
import { useInterval } from "../components/uitls";
// Pages
import Number from "../components/Number";
import Emoji from "../components/Emoji";
import Map from "../components/Map";
import MultipleImage from "../components/MultipleImage";
import MultipleChoice from "../components/MultipleChoice";
import MultipleSelect from "../components/MultipleSelect";
import Picture from "../components/Picture";
import FreeText from "../components/FreeText";
import SlidingScale from "../components/SlidingScale";
import PleaseWait from "../components/PleaseWait";
import Complete from "../components/Complete";
import SessionError from "../components/SessionError";
// Variables
import { uri } from "../App";
import { cookies } from "../App";
// Import context from shared layout
// Context for passing states to components
export const AnsweredContext = createContext();
export const SessionContext = createContext();

export default function Home() {
    //const { sessionId } = useParams();
    const [sessionId, setSessionId] = useState("");
    // States
    const [waiting, setWaiting] = useState(true);
    const [sessionError, setSessionError] = useState(false);
    const [data, setData] = useState(null);
    const [answered, setAnswered] = useState("false");
    //const [session, setSession] = useState(sessionId);
    const [complete, setComplete] = useState("");
    // For page reload, check is session already set via cookie
    useEffect(() => {
        if (cookies.get("session")) {
            if (cookies.get("session") !== sessionId) {
                //setSession(cookies.get("session"));
                cookies.set("session", sessionId, { maxAge: 21600, path: "/" });
                setAnswered(cookies.get("answered"));
            }
        } else {
            setSessionId(sessionId);
            cookies.set("session", sessionId, {
                maxAge: 21600,
            });
        }
    }, [sessionId]);
    // Polling interval of backend
    // Only when session is set
    // Only setData if the ID of incoming question !== ID of question answered (cookie q)
    if (sessionId === "") {
        fetch(uri, {
            headers: { "content-type": "application/json" },
        })
            .then((response) => response.text())
            .then((text) => {
                setSessionId(text);
            });
    }
    useInterval(() => {
        if (data) {
            answered === "true" || data.question_type === "waiting"
                ? setWaiting(true)
                : setWaiting(false);
        }
        if (!sessionError && waiting) {
            fetch(uri + "/session/" + sessionId, {
                headers: { "content-type": "application/json" },
            })
                // First get response as text
                .then((response) => response.text())
                .then((text) => {
                    // Try to parse as JSON
                    try {
                        const resp = JSON.parse(text);
                        if (resp.id !== parseInt(cookies.get("q"))) {
                            setData(resp);
                            // console.log(resp);
                            setAnswered("false");
                            cookies.set("answered", "false", {
                                maxAge: 21600,
                                path: "/",
                            });
                            setComplete("");
                        }
                        // If cannot parse JSON, use text
                    } catch (error) {
                        setComplete(text);
                        setWaiting(false);
                        //setSessionError(true);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setSessionError(true);
                });
        }
        // else {
        //     setSessionError(true);
        // }
    }, 1000);
    // If complete, show complete page
    if (sessionError) {
        return <SessionError />;
    }
    if (complete === "complete") {
        return <Complete />;
    }
    // If question answered, show thank you page
    if (answered === "true") {
        return <PleaseWait state={"answered"} />;
    } else {
        if (!data) {
            // If question not answered and waiting for data show Loading
            return <PleaseWait state={"preload"} />;
        } else {
            // Switch case for question type
            switch (data.question_type) {
                case "waiting":
                    return <PleaseWait state={"intro"} />;
                case "Multiple Choice":
                    return (
                        <AnsweredContext.Provider
                            value={[answered, setAnswered]}>
                            <MultipleChoice
                                id={data.id}
                                session={sessionId}
                                data={data}
                            />
                        </AnsweredContext.Provider>
                    );
                case "Free Text":
                    return (
                        <AnsweredContext.Provider
                            value={[answered, setAnswered]}>
                            <FreeText
                                id={data.id}
                                session={sessionId}
                                data={data}
                            />
                        </AnsweredContext.Provider>
                    );
                case "Multiple Image":
                    return (
                        <AnsweredContext.Provider
                            value={[answered, setAnswered]}>
                            <MultipleImage
                                id={data.id}
                                session={sessionId}
                                data={data}
                            />
                        </AnsweredContext.Provider>
                    );
                case "Map":
                    return (
                        <AnsweredContext.Provider
                            value={[answered, setAnswered]}>
                            <Map id={data.id} session={sessionId} data={data} />
                        </AnsweredContext.Provider>
                    );
                case "Number":
                    return (
                        <AnsweredContext.Provider
                            value={[answered, setAnswered]}>
                            <Number
                                id={data.id}
                                session={sessionId}
                                data={data}
                            />
                        </AnsweredContext.Provider>
                    );
                case "Emoji":
                    return (
                        <AnsweredContext.Provider
                            value={[answered, setAnswered]}>
                            <Emoji
                                id={data.id}
                                session={sessionId}
                                data={data}
                            />
                        </AnsweredContext.Provider>
                    );
                case "Sliding Scale":
                    return (
                        <AnsweredContext.Provider
                            value={[answered, setAnswered]}>
                            <SlidingScale
                                id={data.id}
                                session={sessionId}
                                data={data}
                            />
                        </AnsweredContext.Provider>
                    );

                case "Multi Select":
                    return (
                        <AnsweredContext.Provider
                            value={[answered, setAnswered]}>
                            <MultipleSelect
                                maxOptions={data.max_choices}
                                isImage={data.choices[0].includes(".png")}
                                id={data.id}
                                session={sessionId}
                                data={data}
                            />
                        </AnsweredContext.Provider>
                    );
                case "Point On Image":
                    return (
                        <AnsweredContext.Provider
                            value={[answered, setAnswered]}>
                            <Picture
                                id={data.id}
                                session={sessionId}
                                data={data}
                            />
                        </AnsweredContext.Provider>
                    );

                default:
                    return <PleaseWait />;
            }
        }
    }
}
