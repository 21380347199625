import { convertRange } from "../components/uitls";
import { useState, useContext, useEffect } from "react";
import { AnsweredContext } from "../pages/Home";
import { handleSubmit } from "../components/uitls";

export default function Picture(props) {
    document.getElementById("center").classList.add("fade-in");
    if (document.body.classList.contains("waiting")) {
        document.body.classList.remove("waiting");
    }

    /* eslint-disable no-unused-vars */
    const [answered, setAnswered] = useContext(AnsweredContext);
    /* eslint-enable no-unused-vars */
    const [opacity, setOpacity] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [selection, setSelection] = useState({
        x: "",
        y: "",
    });
    const [marker, setMarker] = useState({
        top: 0,
        left: 0,
    });
    const [isMarker, setIsMarker] = useState(false);
    function handleOnClick(e) {
        e.target.releasePointerCapture(e.pointerId);
        setIsMarker(true);
        var center = document.getElementsByClassName("center")[0];
        setMarker({
            top: 0 - center.getBoundingClientRect().top + e.clientY,
            left: 0 - center.getBoundingClientRect().left + e.clientX,
        });
        var rect = e.target.getBoundingClientRect();
        var height = rect.height;
        var width = rect.width;
        var x = e.clientX - rect.left;
        var y = e.clientY - rect.top;
        var xConvert = Math.round(convertRange(x, [0, width], [0, 100]));
        var yConvert = Math.round(convertRange(y, [0, height], [100, 0]));
        setSelection({ x: xConvert, y: yConvert });
        xConvert < 50
            ? setOpacity(convertRange(xConvert, [0, 50], [1, 0]))
            : setOpacity(convertRange(xConvert, [50, 100], [0, 1]));
    }
    function handleLoad() {}
    useEffect(() => {
        setIsLoaded(true);
    }, []);
    return (
        <>
            {isMarker ? (
                <svg
                    style={{ top: marker.top, left: marker.left }}
                    className="sticky">
                    <circle
                        className="red"
                        cx="25"
                        cy="25"
                        r="20"
                        strokeWidth="5"
                        fill="none"
                    />
                    <circle
                        style={{ opacity: opacity }}
                        className="blue"
                        cx="25"
                        cy="25"
                        r="20"
                        strokeWidth="5"
                        fill="none"
                    />
                </svg>
            ) : (
                <></>
            )}
            <div className="row">
                <div className="col-12 text-center">
                    <h3>{props.data.question}</h3>
                    <p>
                        Place a dot on the image. If you feel very connected to
                        the topic, put yourself closer to the person. If not,
                        put yourself further away.
                    </p>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 text-center">
                    <img
                        onLoad={(e) => handleLoad()}
                        onPointerDown={(e) => handleOnClick(e)}
                        onPointerMove={(e) => handleOnClick(e)}
                        className={
                            isLoaded
                                ? "img-fluid point-in-picture fade-in"
                                : "img-fluid point-in-picture d-none fade-in"
                        }
                        src="particle-person.png"
                        alt={props.choice}
                    />
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-8 offset-2 text-center">
                    <span className="loading-btn-wrapper">
                        <button
                            onClick={(e) =>
                                handleSubmit(
                                    selection.x + ", " + selection.y,
                                    "",
                                    "",
                                    props.id,
                                    props.session,
                                    setAnswered,
                                    0
                                )
                            }
                            id="submit"
                            className="loading-btn">
                            <span className="loading-btn__text">Submit</span>
                        </button>
                    </span>
                </div>
            </div>
        </>
    );
}
