import { useContext, useState, useEffect } from "react";
import { AnsweredContext } from "../pages/Home";
import { uri } from "../App";
import { handleSubmit } from "./uitls";
import { alphabet } from "./uitls";

export default function MultipleImage(props) {
    document.getElementById("center").classList.add("fade-in");

    const [imagesLoaded, setImagesLoaded] = useState([]);
    const [submitted, setSubmitted] = useState({
        choice: "",
        bool: false,
    });
    if (document.body.classList.contains("waiting")) {
        document.body.classList.remove("waiting");
    }

    const [titleStyle, setTitleStyle] = useState("");
    var titleLength = props.data.question.split("").length;
    useEffect(() => {
        titleLength < 50
            ? setTitleStyle("-2")
            : titleLength < 100
            ? setTitleStyle("-1")
            : setTitleStyle("");
    }, [titleLength]);

    const [answerChoice, setAnswerChoice] = useState([]);
    useEffect(() => {
        for (let index = 0; index < props.data.choices.length; index++) {
            setAnswerChoice((current) => [...current, alphabet[index]]);
        }
    }, [props.data.choices]);
    /* eslint-disable no-unused-vars */
    const [answered, setAnswered] = useContext(AnsweredContext);
    /* eslint-enable no-unused-vars */

    function handleLoad(index) {
        setImagesLoaded((temp) => [...temp, index]);
    }
    function handlePreSubmit(index) {
        setSubmitted({ choice: props.data.choices[index], bool: true });
        handleSubmit(
            "",
            answerChoice[index],
            "",
            props.id,
            props.session,
            setAnswered,
            1
        );
    }
    return (
        <>
            <div className="row">
                <div className="col-10 offset-1 text-center">
                    <h1 className={"title" + titleStyle}>
                        {props.data.question}
                    </h1>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    {props.data.choices.map((choice, index) => {
                        return (
                            <div key={choice} className="col-6 mt-5">
                                <img
                                    onLoad={(e) => handleLoad(index)}
                                    onClick={(e) => handlePreSubmit(index)}
                                    id={answerChoice[index]}
                                    className={
                                        imagesLoaded.length ===
                                        props.data.choices.length
                                            ? "img-fluid multi-image fade-in"
                                            : "img-fluid multi-image"
                                    }
                                    style={
                                        imagesLoaded.length ===
                                        props.data.choices.length
                                            ? { opacity: 1 }
                                            : { opacity: 0 }
                                    }
                                    src={uri + choice}
                                    alt={choice}
                                />
                            </div>
                        );
                    })}
                </div>
                {submitted.bool ? (
                    <div className="row selected-image-container fade-in">
                        <div className="col">
                            <img
                                className="selected-image"
                                src={uri + submitted.choice}
                                alt="test"
                            />
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
}
