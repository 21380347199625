import { BrowserRouter, Routes, Route } from "react-router-dom";
import SharedLayout from "./components/SharedLayout";
import Cookies from "universal-cookie";
import Home from "./pages/Home";
export const uri = "https://app.birdmeter.com";
export const cookies = new Cookies();
// * Set debug option for reset button
export const debug = false;
function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<SharedLayout />}>
                    <Route path="/" element={<Home />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
export default App;
