import { cookies } from "../../App";
const Footer = () => {
    return (
        <div id="footer" className="row">
            <div className="col-12 text-center mt-3">
                <button
                    onClick={(e) => {
                        cookies.remove("session");
                        cookies.remove("answered");
                        cookies.remove("q");
                        window.location.reload();
                    }}
                    className="btn btn-danger">
                    {"Reset (Testing)"}
                </button>
            </div>
        </div>
    );
};
export default Footer;
