import { Outlet } from "react-router-dom";
import Footer from "./layout/Footer";
export default function SharedLayout() {
    var urlArray = window.location.href.split("/");
    return (
        <div id="home" className="container">
            <div id="center" className="center">
                <Outlet />
                {urlArray[urlArray.length - 1] === "debug" ? <Footer /> : <></>}
            </div>
        </div>
    );
}
