import { useContext, useEffect } from "react";
import { useState } from "react";
import { AnsweredContext } from "../pages/Home";
import { handleSubmit } from "./uitls";

export default function SlidingScale(props) {
    document.getElementById("center").classList.add("fade-in");
    if (document.body.classList.contains("waiting")) {
        document.body.classList.remove("waiting");
    }
    const [titleStyle, setTitleStyle] = useState("");
    var titleLength = props.data.question.split("").length;
    useEffect(() => {
        titleLength < 50
            ? setTitleStyle("-2")
            : titleLength < 80
            ? setTitleStyle("-1")
            : setTitleStyle("");
    }, [titleLength]);

    /* eslint-disable no-unused-vars */
    const [answered, setAnswered] = useContext(AnsweredContext);
    /* eslint-enable no-unused-vars */
    const [formState, setFormState] = useState({
        answer: "2",
    });
    return (
        <>
            <div className="row">
                <div className="col-10 offset-1 text-center">
                    <h1 className={"title" + titleStyle}>
                        {props.data.question}
                    </h1>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-3 offset-2 text-center">
                    <div className="slider-wrapper">
                        <input
                            onChange={(e) =>
                                setFormState({
                                    ...formState,
                                    answer: e.target.value,
                                })
                            }
                            type="range"
                            min="0"
                            max={props.data.choices.length - 1}
                            className="slider scale"
                            value={formState.answer}
                        />
                    </div>
                </div>
                <div className="col-7 mt-2 emoji-container">
                    {props.data.choices.map((choice, index) => {
                        return (
                            <h3
                                className={
                                    formState.answer === index.toString()
                                        ? "selected-scale"
                                        : "not-selected"
                                }
                                key={choice}>
                                {choice}
                            </h3>
                        );
                    })}
                </div>
                <div className="col-8 offset-2 text-center">
                    <span className="loading-btn-wrapper mt-5">
                        <button
                            onClick={(e) =>
                                handleSubmit(
                                    "",
                                    props.data.choices[formState.answer],
                                    "",
                                    props.id,
                                    props.session,
                                    setAnswered,
                                    0
                                )
                            }
                            id="submit"
                            className="loading-btn">
                            <span className="loading-btn__text">Submit</span>
                        </button>
                    </span>
                </div>
            </div>
        </>
    );
}
