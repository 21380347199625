import { useEffect, useRef } from "react";
import { uri, cookies } from "../App";

//* Polling Interval
export const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
};

//* Normalizer
export function convertRange(value, r1, r2) {
    return ((value - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0]) + r2[0];
}

//* Submit Function
export function handleSubmit(a, c, m, q, s, setAnswered, questionType) {
    //* Get submit button or image button
    var button = document.getElementById("submit");
    if (!button) {
        button = document.getElementById(c);
    }
    //* If image, add hiding class to other images
    if (questionType === 1) {
        var images = document.getElementsByClassName("img-fluid");
        let i = 0,
            len = images.length;
        while (i < len) {
            images[i].classList.add("non-selected-image");
            images[i].style.pointerEvents = "none";

            i++;
        }
        //* If not image start pending animation
    } else button.classList.add("loading-btn--pending");
    setTimeout(() => {
        //* If not image add success animation
        if (questionType !== 1) {
            button.classList.remove("loading-btn--pending");
            button.classList.add("loading-btn--success");
        }
        //* Timeout to send data
        setTimeout(() => {
            if (
                document.getElementById("center").classList.contains("fade-in")
            ) {
                document.getElementById("center").classList.remove("fade-in");
            }
            if (q !== 21) {
                setAnswered("true");
                cookies.set("answered", "true", {
                    maxAge: 21600,
                    path: "/",
                });
            }
            cookies.set("q", q, {
                maxAge: 21600,
                path: "/",
            });
            const requestOptions = {
                method: "post",
                headers: { "content-type": "application/json" },
                body: JSON.stringify({
                    answer: a,
                    choice: c,
                    question: q,
                    session: s,
                    ...(questionType === 2 ? { multi_choice: m } : null),
                }),
            };
            fetch(uri + "/answer", requestOptions)
                .then((response) => response.json())
                .catch((error) => console.error(error));
        }, 1000);
    }, 1000);
}

//* Alphabet for choice options
export const alphabet = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
];

export function stringFix(string) {
    var tempChoice;
    if (string.includes("(")) {
        var start = string.indexOf("(");
        var end = string.indexOf(")") + 1;
        var first = string.split("(")[0];
        var second = string.substring(start, end);
        if (second.split("")[1] === " ") {
            second = second.replace(" ", "");
        }
        if (second.includes("/")) {
            tempChoice = second.split("/");
            second = tempChoice[0] + " / " + tempChoice[1];
        }
        var send = [first, second];
        return send;
    }
    if (string.includes("/")) {
        tempChoice = string.split("/");
        return tempChoice[0] + " / " + tempChoice[1];
    }
    return string;
}
