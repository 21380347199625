import { cookies } from "../App";

export default function Complete(props) {
    if (document.body.classList.contains("waiting")) {
        document.body.classList.remove("waiting");
    }
    cookies.remove("session");
    cookies.remove("answered");
    cookies.remove("q");
    return (
        <>
            <div className="row">
                <div className="col-12 text-center">
                    <h1>Complete!</h1>
                    <p>Thank you for your participation.</p>
                    {props.stayInTouch ? <p>We'll be in touch.</p> : <></>}
                    <svg className="checkmark" viewBox="0 0 52 52">
                        <circle
                            className="checkmark__circle"
                            cx="26"
                            cy="26"
                            r="25"
                            fill="none"
                        />{" "}
                        <path
                            className="checkmark__check"
                            fill="none"
                            d="M14.1 27.2l7.1 7.2 16.7-16.8"
                        />
                    </svg>
                </div>
            </div>
        </>
    );
}
