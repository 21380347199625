import { cookies } from "../App";

export default function SessionError(props) {
    document.body.classList.remove("waiting");

    function handleClick() {
        cookies.remove("session");
        cookies.remove("answered");
        cookies.remove("q");
        window.location.reload();
    }
    /*function handleSubmit() {
        setInfoText("Searching...");
        document.getElementById("submit").classList.add("loading-btn--pending");
        setTimeout(() => {
            setTimeout(() => {
                fetch(uri + "/session/" + formState.sessionCode, {
                    headers: { "content-type": "application/json" },
                })
                    // First get response as text
                    .then(() => {
                        document
                            .getElementById("submit")
                            .classList.remove("loading-btn--pending");
                        document
                            .getElementById("submit")
                            .classList.add("loading-btn--success");
                        setInfoText("Redirecting...");
                        setTimeout(() => {
                            window.location = formState.sessionCode;
                        }, 1000);
                    })
                    .catch(() => {
                        document
                            .getElementById("submit")
                            .classList.remove("loading-btn--pending");
                        document
                            .getElementById("submit")
                            .classList.add("loading-btn--fail");
                        setInfoText("Oops! Looks like that didn't work");
                        setWrongSession(true);
                        setTrys(trys + 1);
                        setTimeout(() => {
                            setInfoText("Let's try again...");
                            setTimeout(() => {
                                setFormState({ sessionCode: "" });
                                document
                                    .getElementById("submit")
                                    .classList.remove("loading-btn--fail");
                            }, 1000);
                        }, 1000);
                    });
            }, 1000);
        }, 1000);
    }*/

    /*if (props.wrongUrl) {
        var urlArray = window.location.href.split("/");
        urlArray.pop();
        urlArray = urlArray.join("/");
        window.location.replace(urlArray);
        return;
    }*/

    return (
        <div className="row">
            <div className="col-10 offset-1 text-center">
                <h3>Oops!</h3>
                <p>
                    It looks like there is a problem with the session. Click
                    here to reload and retry.
                </p>
                <span className="loading-btn-wrapper mt-3">
                    <button
                        onClick={handleClick}
                        id="submit"
                        className="loading-btn">
                        <span className="loading-btn__text">Retry</span>
                    </button>
                </span>
            </div>
        </div>
    );
}
