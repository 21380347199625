import { useContext } from "react";
import { useState } from "react";
import { AnsweredContext } from "../pages/Home";
import { handleSubmit } from "./uitls";
import Complete from "./Complete";

export default function FreeText(props) {
    document.getElementById("center").classList.add("fade-in");
    if (document.body.classList.contains("waiting")) {
        document.body.classList.remove("waiting");
    }
    /* eslint-disable no-unused-vars */
    const [answered, setAnswered] = useContext(AnsweredContext);
    /* eslint-enable no-unused-vars */
    const [stayInTouch, setStayInTouch] = useState(false);
    const [complete, setComplete] = useState(false);
    const [validationText, setValidationText] = useState("placeholder");
    const [isNumber, setIsNumber] = useState(false);
    const [checked, setChecked] = useState(false);
    const [formState, setFormState] = useState({
        answer: "",
    });

    function handleInput(input) {
        setFormState({
            ...formState,
            answer: input,
        });
        formState.answer.match(/^[0-9]+$/)
            ? setIsNumber(true)
            : setIsNumber(false);
    }
    function handleCheck() {
        setChecked(!checked);
    }
    function yesPlease(e) {
        var validationTextElement = document.getElementById("validation_text");
        //var numbers = /^[0-9]+$/;
        if (isNumber && checked) {
            handleSubmit(
                formState.answer,
                "",
                "",
                props.id,
                props.session,
                setAnswered,
                0
            );
            validationTextElement.style.opacity = 1;
            setValidationText("Thank you");
            setStayInTouch(true);
            setTimeout(() => setComplete(true), 2000);
        } else if (!isNumber && !checked) {
            setValidationText(
                "Please enter a valid phone number and confirm you are aged 13 years or older."
            );
            if (validationTextElement.style.opacity === "1") {
                validationTextElement.style.opacity = 0;
                setTimeout(() => {
                    validationTextElement.style.opacity = 1;
                }, 333);
            } else validationTextElement.style.opacity = 1;
        } else if (isNumber && !checked) {
            setValidationText("Please confirm you are aged 13 years or older.");
            if (validationTextElement.style.opacity === "1") {
                validationTextElement.style.opacity = 0;
                setTimeout(() => {
                    validationTextElement.style.opacity = 1;
                }, 333);
            } else validationTextElement.style.opacity = 1;
        } else if (!isNumber && checked) {
            setValidationText("Please enter a valid phone number.");
            if (validationTextElement.style.opacity === "1") {
                validationTextElement.style.opacity = 0;
                setTimeout(() => {
                    validationTextElement.style.opacity = 1;
                }, 333);
            } else validationTextElement.style.opacity = 1;
        }
    }

    return complete ? (
        <Complete stayInTouch={stayInTouch} />
    ) : (
        <>
            <div className="row">
                <div className="col-12 text-center">
                    <h3 className="mb-4">
                        Thank you for participating in Feel Me!
                    </h3>
                    <h5>Would you like to stay in touch?</h5>
                    <p>
                        Please leave your mobile number and we will send you
                        some of the results from today's show. (Your phone
                        number will never be shared with a third party and we
                        will only contact you in relation to this show). To
                        access The Paper Birds Privacy Policy, visit{" "}
                        <a
                            className="privacy-link"
                            href="https://www.thepaperbirds.com/privacy-policy/"
                            target="_blank"
                            rel="noreferrer">
                            www.thepaperbirds.com/privacy-policy
                        </a>
                    </p>

                    <p id="validation_text" className="form_validation">
                        {validationText}
                    </p>
                </div>
            </div>
            <div className="row">
                <input
                    onChange={(e) => {
                        handleInput(e.target.value);
                    }}
                    placeholder="Phone Number..."
                    className="col-10 offset-1"
                    type="number"
                />
            </div>
            <div className="col-12 mt-3" style={{ display: "inline-flex" }}>
                <div className="col-2 offset-2 mt-2">
                    <input
                        type="checkbox"
                        checked={checked}
                        onChange={handleCheck}
                    />
                </div>
                <div className="col-8">
                    <p>
                        Please tick to confirm you are aged 13 years or older.
                    </p>
                </div>
            </div>
            <div className="col-8 offset-2 mt-2 text-center">
                <span className="loading-btn-wrapper">
                    <button
                        onClick={(e) => yesPlease(e)}
                        id="submit"
                        //style={{ backgroundColor: "#ff5722" }}
                        className="loading-btn">
                        <span className="loading-btn__text">Submit</span>
                    </button>
                </span>
            </div>
            {/*<div className="col-8 offset-2 mt-2 text-center">
                <span className="loading-btn-wrapper">
                    <button
                        onClick={(e) => noThanks()}
                        id="submit"
                        style={{ backgroundColor: "#ff5722" }}
                        className="loading-btn">
                        <span className="loading-btn__text">No Thanks</span>
                    </button>
                </span>
                </div>*/}
        </>
    );
}
