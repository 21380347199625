import { useState, useContext } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { AnsweredContext } from "../pages/Home";
import { handleSubmit } from "./uitls";
import Geocode from "react-geocode";
const api = "AIzaSyBRIob1A_tF0a4Z4nJzUz68YMiXODV9URU";
Geocode.setApiKey(api);

const containerStyle = {
    width: "100%",
    height: "300px",
};

const center = {
    lat: 50.806,
    lng: -1.618,
};

export default function Map(props) {
    document.getElementById("center").classList.add("fade-in");
    if (document.body.classList.contains("waiting")) {
        document.body.classList.remove("waiting");
    }
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: api,
    });
    /* eslint-disable no-unused-vars */
    const [answered, setAnswered] = useContext(AnsweredContext);
    /* eslint-enable no-unused-vars */
    const [map, setMap] = useState(/** @type google.maps.Map */ (null));
    const [showTip, setShowTip] = useState(false);
    const [marker, setMarker] = useState();
    const [isMarker, setIsMarker] = useState(false);
    const [address, setAddress] = useState("");
    const [coord, setCoord] = useState({
        lat: "",
        lng: "",
    });

    if (isMarker) {
        Geocode.fromLatLng(coord.lat, coord.lng).then(
            (response) => {
                const address = response.results[0].formatted_address;
                setAddress(address);
            },
            (error) => {
                console.error(error);
            }
        );
    }

    const onMapClick = (e) => {
        var _lat = e.latLng.lat();
        var _lng = e.latLng.lng();
        setIsMarker(true);
        setMarker({ lat: _lat, lng: _lng });
        setCoord({ lat: _lat, lng: _lng });
    };

    setTimeout(() => {
        setShowTip(true);
    }, 3000);

    return isLoaded ? (
        <>
            <div className="row">
                <div className="col-12 text-center">
                    <h1 className="">{props.data.question}</h1>
                </div>
            </div>
            <div className="tip mt-2">
                {showTip ? (
                    <div className="tip-inner fade-in">
                        Tip: You can zoom in!
                    </div>
                ) : (
                    <></>
                )}
            </div>
            <div className="row">
                <div className="col-12 text-center">
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={1}
                        onLoad={map ? map : (map) => setMap(map)}
                        options={{
                            styles: [
                                {
                                    featureType: "poi",
                                    stylers: [{ visibility: "off" }],
                                },
                            ],
                            zoomControl: false,
                            streetViewControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                        }}
                        onClick={onMapClick}>
                        {isMarker ? (
                            <Marker
                                position={{ lat: marker.lat, lng: marker.lng }}
                            />
                        ) : (
                            <></>
                        )}
                    </GoogleMap>
                    <div className="row mt-3">
                        <div className="col text-center">
                            {address ? address : "Click on the map"}
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-8 offset-2 text-center">
                            <span className="loading-btn-wrapper">
                                <button
                                    onClick={(e) =>
                                        handleSubmit(
                                            (
                                                parseFloat(coord.lat).toFixed(
                                                    3
                                                ) +
                                                ", " +
                                                parseFloat(coord.lng).toFixed(3)
                                            ).toString(),
                                            "",
                                            "",
                                            props.id,
                                            props.session,
                                            setAnswered,
                                            0
                                        )
                                    }
                                    id="submit"
                                    className="loading-btn">
                                    <span className="loading-btn__text">
                                        Submit
                                    </span>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    ) : (
        <></>
    );
}
