export default function PleaseWait(props) {
    document.body.classList.add("waiting");
    return (
        <div className="fade-in">
            <div className="row">
                <div className="col-12">
                    {props.state === "intro" ? (
                        <IsIntro />
                    ) : props.state === "answered" ? (
                        <NotIntro />
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    );
}

function IsIntro() {
    return (
        <h1 className="m-auto waiting-text text-center">
            Welcome to today's performance of <strong>Feel Me</strong>.
            <br />
            <br />
            You’re in the right place, please wait here.
        </h1>
    );
}

function NotIntro() {
    return <h1 className="m-auto waiting-text text-center">Please wait...</h1>;
}
